import * as am4core from "@amcharts/amcharts4/core";
import am4themes_animated from "@amcharts/amcharts4/themes/animated";

import "./style.css";

const urlParams = new URLSearchParams(window.location.search);
const visualizationId = urlParams.get("vId");
const token = urlParams.get("t");
const hasPrint = urlParams.has("print");

if (!isEmpty(visualizationId) && !isEmpty(token)) {
  document.getElementById("displayDiv").setAttribute("id", visualizationId);
  document
    .getElementById(visualizationId)
    .addEventListener("click", (event) => c++);

  const url = `${API_URL}/export-visualization/visualization/${visualizationId}?token=${token}`;
  httpGetAsync(url, (response) => {
    if (!isEmpty(response)) {
      displayChart(response, hasPrint);
    }
  });
}

function isEmpty(str) {
  return !str || str.length == 0;
}

function httpGetAsync(url, callback) {
  var xmlHttp = new XMLHttpRequest();
  xmlHttp.open("GET", url, true);
  xmlHttp.onreadystatechange = function () {
    if (xmlHttp.readyState == 4 && xmlHttp.status == 200) {
      callback(xmlHttp.responseText);
    }
  };
  xmlHttp.send(null);
}

function displayChart(response, hasPrint) {
  const visualization = JSON.parse(response);

  if (hasPrint) {
    import("@fontsource/roboto-slab");
    document.body.classList.add("print-font");
  } else {
    import("@fontsource/roboto");
  }

  initializeText(visualization);
  initializeChart(visualization);
}

function initializeText(visualization) {
  const text = visualization.visualizationText;
  const label = text.chartLabel;
  const description = text.chartDescription;
  const finePrint = text.chartFineprint;

  const chartLabelElem = document.getElementById("chartLabel");
  chartLabelElem.innerText = label;
  chartLabelElem.title = label;

  const chartDescriptionElem = document.getElementById("chartDescription");
  chartDescriptionElem.innerText = description;
  chartDescriptionElem.title = description;

  document.getElementById("chartFineprint").innerHTML = finePrint;
}

function initializeChart(visualization) {
  am4core.addLicense("CH294866104");
  am4core.addLicense("MP294866104");
  am4core.useTheme(am4themes_animated);
  am4core.options.autoSetClassName = true;
  am4core.options.disableHoverOnTransform = "touch";

  importAmCharts(visualization.chart.type).then(function () {
    createChart(visualization);
  });
}

function importAmCharts(chartType) {
  switch (chartType) {
    case "MapChart":
      return import("@amcharts/amcharts4/maps");
    case "CurveChart":
    case "SerpentineChart":
    case "SpiralChart":
      return Promise.all([
        import("@amcharts/amcharts4/charts"),
        import("@amcharts/amcharts4/plugins/timeline"),
      ]);
    case "PieChart":
      return Promise.all([
        import("@amcharts/amcharts4/charts"),
        import("@amcharts/amcharts4/plugins/sliceGrouper"),
      ]);
    default:
      return import("@amcharts/amcharts4/charts");
  }
}

function createChart(visualization) {
  const chart = am4core.createFromConfig(
    visualization.chart,
    visualization.id,
    visualization.chart.type
  );

  chart.data.forEach((d) => {
    d.data.__index = d.index;
  });
  chart.data = visualization.chart.data.map((v) => v.data);

  chart.width = am4core.percent(100);
  chart.height = am4core.percent(100);

  chart.events.on("ready", (event) => {
    const colorsList =
      visualization.chartCreationOptions.colorOptions.individualColors;
    applyColors(chart, colorsList);

    //amcharts bug?
    if (visualization.chart.legend != null) {
      chart.legend.valign = visualization.chart.legend.valign;
    }
  });
}

function applyColors(chart, colorsList) {
  const seriesArray = chart.series.values;
  for (let i = 0; i < seriesArray.length; i++) {
    const series = seriesArray[i];
    const colors = colorsList.find((c) => c.index === i);

    if (colors == null) {
      continue;
    }

    for (let color of colors.colors) {
      if (series.className === "ColumnSeries") {
        applyIndividualColumnColor(color.color, series, color.index);
      } else if (series.className === "PieSeries") {
        applyIndividualPieColor(color.color, series, color.index);
      }
    }
  }
}

function applyIndividualColumnColor(color, series, colorIndex) {
  for (const column of series.columns) {
    const dataContext = column.dataItem.dataContext;
    if (dataContext.__index === colorIndex) {
      column.fill = am4core.color(color);
      column.stroke = am4core.color(color);
    }
  }
}

function applyIndividualPieColor(color, series, colorIndex) {
  for (const slice of series.slices) {
    const dataContext = slice.dataItem.dataContext;
    if (dataContext.__index === colorIndex) {
      slice.fill = am4core.color(color);
      slice.stroke = am4core.color(color);
    }
  }
}
